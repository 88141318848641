import React, { Fragment, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import IconChair from '../../../assets/images/dashboard/chair.svg';
import IconBox from '../../../assets/images/dashboard/box.svg';
import IconMessage from '../../../assets/images/dashboard/message.svg';
import { getLockerList, getMerchantInfo, getSeatList } from '../../../lib/api';
import { numberWithCommas } from '../../common';
import { useHistory } from 'react-router-dom';
import { DashboardCard } from '../../commonStyle';
import useHandleAPICall from '../../../lib/hooks/useHandleAPICall';
import useHandleError from '../../../lib/hooks/useHandleError';
import Format from '../../../util/formatter';
/** 좌석,사물함, 메시지 사용량 component */

const UsageData = styled.div`
  display: grid;
  grid-template-columns: 5rem auto;
  gap: 1rem;
  width: 100%;
  .titleImg {
    display: flex;
    border: 1px solid #dde4eb;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 2rem;
    }
  }
  .text {
    width: 100%;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > button {
        font-size: 1rem;
        padding: 0.125rem 0.5rem;
        color: #91979e;
        background: none;
        border: 1px solid #dde4eb;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #f1f3f6;
        }
      }
      > p {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.25rem;
      }
    }
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;
      font-size: 2rem;
      color: ${(props) =>
        props.color === 'red'
          ? ' #D31F40'
          : props.color === 'orange'
          ? '#D75E1B'
          : 'var(--dashboard-default)'};
    }
  }
`;

const StyleComponent = styled(DashboardCard)`
  display: flex;
  /* height: 10vw; */
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  progress {
    width: 100%;
    border-radius: 0.75rem;
    height: 1.5rem;
    /* margin-bottom: 2.22vw; */
  }
  progress::-webkit-progress-bar {
    width: 100%;
    border-radius: 0.75rem;
    background: #f1f3f6;
  }
  progress::-webkit-progress-value {
    border-radius: 0.75rem;
    background: ${(props) =>
      props.color === 'red' ? '#EC6E85' : props.color === 'orange' ? '#F2A354' : '#00cfe2'};
  }
  progress::-moz-progress-bar {
    width: 100%;
    border-radius: 0.75rem;
    background: #f1f3f6;
  }
  .totalText {
    display: flex;
    p {
      font-size: 1rem;
      margin-bottom: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }
`;

const UsageComponent = ({ merchantId, type, itemRef }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();
  const [share, setShare] = useState();
  // const [seatLength, setSeatLength] = useState(0);
  // const [occData, setOccData] = useState(0);
  // const [usingLocker, setUsingLocker] = useState(0);
  // const [lockerLength, setLockerLength] = useState(0);
  // const [usingMassage, setUsingMassage] = useState(0);
  // const [massageLength, setMassageLength] = useState(0);
  const history = useHistory();

  const [seatCount, setSeatCount] = useState({
    total: 0,
    occupied: 0,
    unavailable: 0,
  });
  const [lockerCount, setLockerCount] = useState({
    total: 0,
    occupied: 0,
    unavailable: 0,
  });
  const [messageCount, setMessageCount] = useState({
    total: 0,
    used: 0,
  });

  const fetch = async (merchantId) => {
    if (type === 'seat') seatData(merchantId);
    else if (type === 'locker') LockerData(merchantId);
    else messageData(merchantId);
  };
  const seatData = async (e) => {
    try {
      const data = await createRequest(getSeatList, e);
      // const usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatCount({
        total: data.data.length,
        occupied: data.data.filter((item) => item.isUsing).length,
        unavailable: data.data.filter((item) => item.status === 'block').length,
      });

      // setSeatLength(data.data.length);
      // setOccData(usingFilterData.length);

      setShare(
        Math.floor(
          (data.data.filter((item) => item.status !== 'idle').length / data.data.length) * 100,
        ),
      );
    } catch (error) {
      catchError(error);
    }
  };

  const LockerData = async (e) => {
    try {
      const data = await createRequest(getLockerList, e);
      setLockerCount({
        total: data.data.length,
        occupied: data.data.filter((item) => item.status === 'using').length,
        unavailable: data.data.filter((item) => item.status === 'block').length,
      });
      // setLockerLength(data.data.length);
      // setUsingLocker(data.data.filter((item) => item.userId).length);
      // setShare(
      //   Math.floor((data.data.filter((item) => item.userId).length / data.data.length) * 100),
      // );
      setShare(
        Math.floor(
          (data.data.filter((item) => item.status !== 'idle').length / data.data.length) * 100,
        ),
      );
    } catch (error) {
      catchError(error);
    }
  };

  const messageData = async (e) => {
    try {
      const res = await createRequest(getMerchantInfo, e);
      setMessageCount({ total: res.data[0].maxSendMessage, used: res.data[0].sendMessageUseCount });
      // setMassageLength(res.data[0].maxSendMessage);
      // setUsingMassage(res.data[0].sendMessageUseCount);
      setShare(Math.floor((res.data[0].sendMessageUseCount / res.data[0].maxSendMessage) * 100));
    } catch (error) {
      catchError(error);
    }
  };

  const goMessagePage = () => {
    history.push('/message/charge');
  };
  useEffect(() => {
    if (!merchantId) return;
    fetch(merchantId);
  }, [merchantId]);

  const CurrentChartData = useMemo(() => {
    const fallback = { max: 0, value: 0 };
    if (type === 'seat')
      return { max: seatCount.total, value: seatCount.occupied + seatCount.unavailable };
    if (type === 'locker')
      return { max: lockerCount.total, value: lockerCount.occupied + lockerCount.unavailable };
    if (type === 'message') return { max: messageCount.total, value: messageCount.used };
    return fallback;
  }, [type, seatCount, lockerCount, messageCount]);

  return (
    <Fragment>
      <StyleComponent
        ref={itemRef}
        color={
          type === 'message' && share > 89
            ? 'red'
            : type === 'message' && share > 49
            ? 'orange'
            : 'nomal'
        }>
        <UsageData
          color={
            type === 'message' && share > 89
              ? 'red'
              : type === 'message' && share > 49
              ? 'orange'
              : 'nomal'
          }>
          <div className="titleImg">
            <img
              src={type === 'seat' ? IconChair : type === 'locker' ? IconBox : IconMessage}
              alt="icon"
            />
          </div>
          <div className="text">
            <div>
              <p>
                {type === 'seat'
                  ? '좌석 점유율'
                  : type === 'locker'
                  ? '사물함 점유율'
                  : '메시지 사용량'}
              </p>
              {type === 'message' && <button onClick={() => goMessagePage()}>충전</button>}
            </div>

            <p>{share ? share : 0}%</p>
          </div>
        </UsageData>
        {/* <progress
          max={type === 'seat' ? seatCount.total : type === 'locker' ? lockerLength : massageLength}
          value={
            type === 'seat' ? seatCount.occupied : type === 'locker' ? usingLocker : usingMassage
          }
        /> */}
        <progress max={CurrentChartData.max} value={CurrentChartData.value} />

        <div className="totalText">
          <p>
            {type === 'seat'
              ? `${Format.formatNumber(seatCount.occupied)}석 사용 중, ${Format.formatNumber(
                  seatCount.unavailable,
                )}석 사용 불가`
              : type === 'locker'
              ? `${Format.formatNumber(lockerCount.occupied)}개 사용 중, ${Format.formatNumber(
                  lockerCount.unavailable,
                )}개 사용 불가`
              : `${Format.formatNumber(messageCount.used)}P 사용`}
          </p>
          &nbsp;
          <p>
            / 총{' '}
            {type === 'seat'
              ? `${Format.formatNumber(seatCount.total)}석`
              : type === 'locker'
              ? `${Format.formatNumber(lockerCount.total)}개`
              : `${Format.formatNumber(messageCount.total)}P`}
          </p>
        </div>
      </StyleComponent>
    </Fragment>
  );
};

export default UsageComponent;
