import React, { useState } from 'react';
import PageContainer from '../../components/common/PageContainer';
import { Content, ContentArea, MessageSenderWriter } from '../../components/Message';

import { CheckBox, RadioBtn, RadioLabel } from '../../components/commonStyle';
import styled from 'styled-components';
import { postMessageCondition } from '../../lib/api/v2/message';
import { swalMessage, swalSuccess } from '../../components/common';
import { useHistory } from 'react-router-dom';
import Disclaimer from '../../components/common/Disclaimer';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';

// 전체: 0
const TICKET_OPTIONS = [
  { key: 1, name: '일회권' },
  { key: 2, name: '기간권' },
  { key: 3, name: '시간정액권' },
  { key: 4, name: '세미나' },
  { key: 5, name: '사물함' },
  { key: 6, name: '고정석' },
];

const TARGET_OPTIONS = [
  { key: 2, name: '전체' },
  { key: 1, name: '현재 입장한 회원' },
  { key: 0, name: '현재 입장하지 않은 회원' },
];

const MessageBulkV2 = () => {
  const { createRequest } = useHandleAPICall();

  const history = useHistory();
  const [isAd, setIsAd] = useState(false);

  const [checkboxEnabled, setCheckboxEnabled] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });
  const [selectedTarget, setSelectedTarget] = useState(2);

  const handleSend = async (
    image: File | null,
    message: string,
    messageTitle: string,
    messagePrefix?: string,
    messagePostfix?: string,
  ) => {
    const noneChecked = Object.values(checkboxEnabled).reduce((pre, cur) => pre && !cur, true);
    if (noneChecked) return;
    if (!message) return swalMessage('warning', '메시지를 입력해 주세요.', '확인', false);
    try {
      const form = new FormData();
      const ticketType: any[] = [];
      Object.keys(checkboxEnabled).forEach((key) => {
        checkboxEnabled[key as unknown as keyof typeof checkboxEnabled] && ticketType.push(key);
      });
      if (image) form.append('image', image);
      if (messageTitle) form.append('title', messageTitle);
      form.append('message', `${messagePrefix || ''}${message}${messagePostfix || ''}`);
      form.append('isUsing', selectedTarget.toString());
      form.append('type', ticketType.join(','));
      form.append('isAD', JSON.stringify(isAd));
      await createRequest(postMessageCondition, form);

      swalSuccess('메시지가 전송').then(() => {
        history.go(0);
      });
    } catch (error) {
      const { data } = error as any;
      const message = data || '전송에 실패했습니다.';
      swalMessage(
        'error',
        message,
        '확인',
        false,
        () => history.go(0),
        () => history.go(0),
      );
    }
  };

  return (
    <PageContainer title="단체 메시지 전송">
      <ContentArea>
        <Content>
          <Content title="이용권 선택">
            <FlexHorizontal>
              <RadioLabel>
                <CheckBox
                  checked={Object.values(checkboxEnabled).reduce((pre, cur) => pre && cur, true)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked)
                      return setCheckboxEnabled({
                        1: true,
                        2: true,
                        3: true,
                        4: true,
                        5: true,
                        6: true,
                      });
                    return setCheckboxEnabled({
                      1: false,
                      2: false,
                      3: false,
                      4: false,
                      5: false,
                      6: false,
                    });
                  }}
                />
                전체
              </RadioLabel>
              {TICKET_OPTIONS.map((option) => (
                <RadioLabel>
                  <CheckBox
                    checked={checkboxEnabled[option.key as 1 | 2 | 3 | 4 | 5 | 6]}
                    onChange={(e) =>
                      setCheckboxEnabled({ ...checkboxEnabled, [option.key]: e.target.checked })
                    }
                  />
                  {option.name}
                </RadioLabel>
              ))}
            </FlexHorizontal>
          </Content>
          <Content title="대상 선택">
            <FlexHorizontal>
              {TARGET_OPTIONS.map((option) => (
                <RadioLabel>
                  <RadioBtn
                    checked={option.key === selectedTarget}
                    onClick={() => setSelectedTarget(option.key)}
                  />
                  {option.name}
                </RadioLabel>
              ))}
            </FlexHorizontal>
          </Content>
          <Content title="광고 문자 여부">
            <RadioLabel>
              <CheckBox checked={isAd} onChange={(e) => setIsAd(e.target.checked)} />
              (광고) 문구 추가 표시
            </RadioLabel>
            <Disclaimer
              type="warning"
              message={`광고성 문자는 정보통신망법 제50조에 따라 "(광고)" 문구 표기가 필수입니다. \n 이를 표시하지 않을 경우 과태료가 부과될 수 있으니 꼭 확인해주세요.`}
            />
          </Content>
        </Content>
        <MessageSenderWriter
          handleSend={handleSend}
          messagePrefix={isAd ? '(광고)' : ''}
          messagePostfix={
            isAd
              ? '\n\n수신거부할 경우, 렛미업에서 발송하는 알림 문자를 받을 수 없습니다.\n무료거부 080*******'
              : ''
          }
        />
      </ContentArea>
    </PageContainer>
  );
};

export default MessageBulkV2;

const FlexHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0;
`;
